import VXButton                 from "../VXButton";
import React                    from 'react';
import PropTypes                from 'prop-types';
import {VXButtonLineConfig}     from "../VXButtonConfig";
import STYLES                   from './../EnumVXButton';
import {COLOR_TYPE_TRANSPARENT,
        COLOR_BLACK,}            from "../ButtonHelper";

class VXButtonOutline extends React.PureComponent {
	render() {
		return (
			<VXButton color={this.props.color}
			          border={this.props.border}
			          line1={this.props.line1}
			          line2={this.props.line2}
			          icon={this.props.icon}
					  navigateTo={this.props.navigateTo}
			          onClick={this.props.onClick}
			          link={this.props.link}
			          onMouseDown={this.props.onMouseDown}
			          disabled={this.props.disabled}
			          animateSuccess={this.props.animateSuccess}
			          staticMode={this.props.staticMode}
			          iconPosition={this.props.iconPosition}
					  callback={this.props.callback}
			          size={this.props.size}
			/>
		);
	}

}

VXButtonOutline.propTypes = {
	line1: PropTypes.arrayOf(PropTypes.instanceOf(VXButtonLineConfig)).isRequired,
	line2: PropTypes.arrayOf(PropTypes.instanceOf(VXButtonLineConfig)),

	/**
	 * e.g. "-icon-search-full"
	 */
	icon:    PropTypes.string,
	onClick: PropTypes.func,
	/**
	 * If link is given onClick will be ignored and the Button will be rendered as <a/> with default handling
	 */
	link:         PropTypes.string,
	onMouseDown:  PropTypes.func,
	disabled:     PropTypes.bool,
	iconPosition: PropTypes.oneOf(STYLES.ICON_POSITIONS),
	/**
	 * Should we show checkmark / cross
	 */
	animateSuccess: PropTypes.bool,
	staticMode:     PropTypes.bool,
	callback:       PropTypes.func,
	size:           PropTypes.oneOf(Object.values(STYLES.SIZE)),
    color:          PropTypes.oneOf([COLOR_TYPE_TRANSPARENT, COLOR_BLACK]),
    border:         PropTypes.oneOf([STYLES.BORDER.OUTLINE, STYLES.BORDER.NONE]),
	navigateTo:     PropTypes.func,
};

VXButtonOutline.defaultProps = {
	line2:          [],
	icon:           null,
	disabled:       false,
	animateSuccess: false,
	border:         STYLES.BORDER.OUTLINE,
	staticMode:     false,
	iconPosition:   STYLES.ICON_POSITION.LEFT,
	link:           null,
	size:           STYLES.SIZE.SMALL,
    color:          COLOR_TYPE_TRANSPARENT,
	navigateTo:     null,
};

export default VXButtonOutline;
